import React from 'react';
import Content from '../views/AboutUs';

export default ({location}) => <Content location={location}  seo={{
  title: "A leading connected car service provider in Europe",
  lang:'en',
  description: "OBI+ a tech company providing infrastructure, application platform, and digital services that create value, deliver insights, and enable growth.",
  meta: [{
    name: 'keywords',
    content: 'Connected car service provider',
  }]
}}/>